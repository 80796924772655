import React from 'react';

const ArticleFilter = ({ onFilterApply, authors, categories }) => {
  const [filterInputs, setFilterInputs] = React.useState({
    title: '',
    author: '',
    category: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onFilterApply(filterInputs);
    }
  };

  return (
    <div className="filter-container">
      <input
        type="text"
        name="title"
        placeholder="Search by title"
        value={filterInputs.title}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress} // Detect "Enter" key press
        className="filter-input"
      />
      <select
        name="author"
        value={filterInputs.author}
        onChange={handleInputChange}
        className="filter-select"
      >
        <option value="">All Authors</option>
        {authors.map((author, index) => (
          <option key={index} value={author}>
            {author}
          </option>
        ))}
      </select>
      <select
        name="category"
        value={filterInputs.category}
        onChange={handleInputChange}
        className="filter-select"
      >
        <option value="">All Categories</option>
        {categories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
      <button onClick={() => onFilterApply(filterInputs)} className="filter-button">
        Filter
      </button>
    </div>
  );
};

export default ArticleFilter;
