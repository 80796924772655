// src/pages/Contact.js
import React, { useState } from 'react';
import '../css/contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Allow only digits and format the phone number
      const phoneNumber = formatPhoneNumber(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: phoneNumber,
      }));
    } else if (name === 'email') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      // Validate email format
      validateEmail(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    const encodedData = new URLSearchParams({
      'form-name': 'contact',
      ...formData,
    }).toString();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodedData,
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.error('Form submission error:', error);
        alert('Failed to send message. Please try again later.');
      });
  };

  const handleReset = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
    setSubmitted(false);
    setEmailError(false);
  };

  const validateEmail = (email) => {
    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email));
  };

  const formatPhoneNumber = (value) => {
    // Remove non-digit characters
    const phoneNumber = value.replace(/\D/g, '');
    // Format phone number as (123) 456-7890
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <div className="contact-page">
      {submitted ? (
        <div className="success-message">
          <p>Your message was sent successfully!</p>
          <button onClick={handleReset}>Send Another Message</button>
        </div>
      ) : (
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="center">
            <h1>Contact Us</h1>
          </div>
          
          <input type="hidden" name="form-name" value="contact" />

          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="(123) 456-7890"
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {emailError && <p className="error-text">Please enter a valid email address.</p>}

          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="5" // Set default rows to 5
            required
          />

            <div className='center'>
                <button type="submit" disabled={submitted || emailError}>
                    {submitted ? 'Sending...' : 'Send Message'}
                </button>
            </div>
        </form>
      )}
    </div>
  );
};

export default Contact;