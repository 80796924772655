import React, { useState, useEffect } from 'react';
import events from '../events/events.json';
import '../css/calendar.css';

const getCalendarDays = (year, month) => {
  const firstDay = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  let days = [];
  for (let i = 0; i < firstDay; i++) {
    days.push(null); // Add empty days for the previous month
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }
  return days;
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarDays, setCalendarDays] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]); // Track events for the selected day
  const [selectedDay, setSelectedDay] = useState(null); // Track selected day for mobile

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    setCalendarDays(getCalendarDays(year, month));
  }, [currentDate]);

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const openEventModal = (event) => {
    setSelectedEvent(event);
  };

  const closeEventModal = () => {
    setSelectedEvent(null);
  };

  // Open the modal for the selected day (on mobile)
  const openDayModal = (day) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const dayEvents = events.filter(event => event.date === `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
    setSelectedDay(day);
    setSelectedDayEvents(dayEvents);
  };

  // Close the modal for the selected day
  const closeDayModal = () => {
    setSelectedDay(null);
    setSelectedDayEvents([]);
  };

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  return (
    <div className="calendar">
      <div className="calendar-header">
        <i className="bi bi-arrow-left-circle calendar-arrow" onClick={handlePrevMonth}></i>
        <h2>{monthNames[month]} {year}</h2>
        <i className="bi bi-arrow-right-circle calendar-arrow" onClick={handleNextMonth}></i>
      </div>
      <div className="calendar-grid">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div className="calendar-day-name" key={day}>{day}</div>
        ))}
        {calendarDays.map((day, index) => (
          <div className="calendar-day" key={index} onClick={() => openDayModal(day)}>
            {day && (
              <>
                <span className="day-number">{day}</span>
                {events
                  .filter(event => event.date === `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`)
                  .map(event => (
                    <React.Fragment key={event.title}>
                      <button className="event-title" onClick={() => openEventModal(event)}>
                        {event.title}
                      </button>
                      <hr className="event-bar" />
                    </React.Fragment>
                  ))}
              </>
            )}
          </div>
        ))}
      </div>

      {/* Day Modal for Mobile */}
      {selectedDay && (
        <div className="day-modal">
          <div className="day-modal-content">
            <span className="close-button" onClick={closeDayModal}>&times;</span>
            <h3>{monthNames[month]} {selectedDay}, {year}</h3>
            {selectedDayEvents.length > 0 ? (
              selectedDayEvents.map(event => (
                <button
                  key={event.title}
                  className="day-event-title"
                  onClick={() => openEventModal(event)}
                >
                  {event.title}
                </button>
              ))
            ) : (
              <p>No events for this day.</p>
            )}
          </div>
        </div>
      )}

      {/* Event Modal */}
      {selectedEvent && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeEventModal}>&times;</span>
            <h3>{selectedEvent.title}</h3>
            <p>{selectedEvent.description}</p>
            <p><strong>Location:</strong> {selectedEvent.location}</p>
            <a href={selectedEvent.link} target='_blank' rel="noopener noreferrer">See More</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
