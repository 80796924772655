// src/pages/ContactPage.js
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import '../css/contact.css';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  // Define the mapping between ad names and URLs
  const adLinks = {
    "RVTUS": "https://rvtus.com/",
    "Cannaroos": "https://www.curationsco.com/Brands/Cannaroos",
    "Locals": "https://localscannahouse.com/",
    "Terpene": "https://terpenetransit.com/"
  };

  const getRectangularAds = () => {
    const adsContext = require.context('../../public/assets/RectangularAds', false, /\.(png|jpe?g|svg|gif|webp)$/);
    return adsContext.keys().map(adsContext);
  };

  const getSquareAds = () => {
    const adsContext = require.context('../../public/assets/SquareAds', false, /\.(png|jpe?g|svg|gif)$/);
    return adsContext.keys().map(adsContext);
  };

  const ads = getRectangularAds();
  const squareAds = getSquareAds();

  // Helper function to select unique ads
  const getUniqueAds = (adArray, count) => {
    const uniqueAds = [];
    const adPool = [...adArray];

    for (let i = 0; i < count && adPool.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * adPool.length);
      uniqueAds.push(adPool[randomIndex]);
      adPool.splice(randomIndex, 1); // Ensure uniqueness
    }

    return uniqueAds;
  };

  const [bottomAd] = useState(ads[Math.floor(Math.random() * ads.length)]);
  const [rightSideAds] = useState(getUniqueAds(squareAds, 3)); // Get 3 unique ads for the right side only

  // Helper function to get the URL based on the ad filename
  const getAdUrl = (ad) => {
    const adName = ad.split('/').pop().split('.')[0]; // Extract the ad name from the file path
    return adLinks[adName]; // Get the corresponding URL from the mapping
  };

  return (
    <div id="root">
      <Helmet>
        <title>Contact Us | 502Culture</title>
        <meta name="description" content="Contact 502Culture for inquiries, feedback, or collaboration. We’d love to hear from the Spokane cannabis community." />
      </Helmet>
      <Header />
      <main className="contact-main">
        <div className="ad-container">
          <div className="contact-content">
            {/* Contact Form */}
            <section className="contact-section">
              <Contact />
            </section>

            {/* Bottom rectangular ad */}
            {bottomAd && (
              <div className="cont-rect-ad-space">
                {getAdUrl(bottomAd) ? (
                  <a href={getAdUrl(bottomAd)} target="_blank" rel="noopener noreferrer">
                    <img src={bottomAd} alt="Advertisement" className="cont-rectangular-ad" />
                  </a>
                ) : (
                  <img src={bottomAd} alt="Advertisement" className="cont-rectangular-ad" />
                )}
              </div>
            )}
          </div>

          {/* Right side square ads */}
          <div className="side-ad-space">
            {rightSideAds.map((ad, index) => (
              getAdUrl(ad) ? (
                <a key={`right-ad-${index}`} href={getAdUrl(ad)} target="_blank" rel="noopener noreferrer">
                  <img src={ad} alt="Advertisement" className="side-square-ad right" />
                </a>
              ) : (
                <img key={`right-ad-${index}`} src={ad} alt="Advertisement" className="side-square-ad right" />
              )
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
