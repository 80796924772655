import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Calendar from '../components/Calendar';
import events from '../events/events.json';
import '../css/homepage.css';
import articles from '../articles/articles.json';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  const today = new Date();

  const upcomingEvents = events
    .filter(event => new Date(event.date) >= today)
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .slice(0, 5);
  const sliderRef = useRef(null);

  const sortedArticles = [...articles].sort(
    (a, b) => new Date(b['date-uploaded']) - new Date(a['date-uploaded'])
  );

  const recentArticles = sortedArticles.slice(0, 10);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div id="root">
      <Helmet>
        <title>502Culture | Spokane's Cannabis News and Community</title>
        <meta name="description" content="Stay informed with the latest cannabis news, events, and community insights in Spokane. 502Culture brings together cannabis enthusiasts and industry professionals." />
      </Helmet>
      <Header />
      <main className="homepage-main">
        <section className="article-summary-section">
          <div className="recent-articles-header">
            <div className="custom-arrow left-arrow" onClick={previous}>
              <i className="bi bi-arrow-left-circle calendar-arrow"></i>
            </div>
            <h2 className='homepage-section-header'>Recent Articles</h2>
            <div className="custom-arrow right-arrow" onClick={next}>
              <i className="bi bi-arrow-right-circle calendar-arrow"></i>
            </div>
          </div>
          <hr></hr>
          <div className="articles-container">
            <Slider ref={sliderRef} {...settings}>
              {recentArticles.map((article, index) => (
                <div key={index} className="article-summary">
                  <div className='img-cont'>
                    <img
                      src={article['image-path']}
                      alt={article.title}
                      className="article-image"
                    />
                  </div>
                  <h3>{article.title}</h3>
                  <p>{article.summary}</p>
                  <a href={`/all-articles#article-${index}`}>Read More</a>
                </div>
              ))}
            </Slider>
          </div>
        </section>

        <div key='ad1' className="rect-ad-space">
          <a href='https://www.curationsco.com/Brands/Cannaroos' target='_blank' rel="noopener noreferrer">
            <img src='/assets/RectangularAds/Cannaroos.png' alt="Advertisement" className='home-rectangular-ad'/>
          </a>
        </div>

        <section className="special-events-section">
          <h2 className="homepage-section-header">Special Events</h2>
          <hr />
          <div className="events-container">
            {upcomingEvents.length > 0 ? (
              upcomingEvents.map((event, index) => (
                <div className="event-spotlight" key={index}>
                  <h3>{event.title}</h3>
                  <p><strong>Date:</strong> {new Date(event.date).toLocaleDateString()}</p>
                  <p><strong>Location:</strong> {event.location}</p>
                  <p><strong>Details:</strong> {event.description}</p>
                  <a
                    href={event.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="event-link"
                  >
                    Learn more
                  </a>
                </div>
              ))
            ) : (
              <p>No upcoming events at the moment. Check back soon!</p>
            )}
          </div>
        </section>

        <div key='ad2' className="dual-ads">
          <a href='https://localscannahouse.com/' target='_blank' rel="noopener noreferrer">
            <img src='/assets/RectangularAds/Locals.png' alt="Advertisement" className='dual-ad'/>
          </a>
          <a href='https://terpenetransit.com/' target='_blank' rel="noopener noreferrer">
            <img src='/assets/SquareAds/Terpene.png' alt="Advertisement" className='dual-ad'/>
          </a>
        </div>

        <section className="calendar-section">
          <h2 className='homepage-section-header'>Event Calendar</h2>
          <hr></hr>
          <Calendar />
        </section>

        <div key='ad3' className="rect-ad-space">
          <a href='https://rvtus.com/' target='_blank' rel="noopener noreferrer">
            <img src='/assets/RectangularAds/RVTUS.png' alt="Advertisement" className='home-rectangular-ad'/>
          </a>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
