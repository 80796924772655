import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ArticleCard from '../components/ArticleCard';
import ArticleFilter from '../components/ArticleFilter';
import articlesData from '../articles/articles.json';
import { Helmet } from 'react-helmet';

const AllArticles = () => {
  const [filteredArticles, setFilteredArticles] = useState(articlesData);
  
  const authors = Array.from(new Set(articlesData.map(article => article.author)));
  const categories = Array.from(new Set(articlesData.map(article => article.category)));

  const articleRefs = useRef([]); // Array of refs for each article

  // Define the mapping between ad names and URLs
  const adLinks = {
    "RVTUS": "https://rvtus.com/",
    "Cannaroos": "https://www.curationsco.com/Brands/Cannaroos",
    "Locals": "https://localscannahouse.com/",
    "Terpene": "https://terpenetransit.com/"
  };

  // Function to get random ads from the RectangularAds directory dynamically
  const getRectangularAds = () => {
    const adsContext = require.context('../../public/assets/RectangularAds', false, /\.(png|jpe?g|svg|gif|webp)$/);
    return adsContext.keys().map(adsContext);
  };

  const getSquareAds = () => {
    const adsContext = require.context('../../public/assets/SquareAds', false, /\.(png|jpe?g|svg|gif)$/);
    return adsContext.keys().map(adsContext);
  };

  const ads = getRectangularAds(); // Get the rectangular ads dynamically from the directory
  const squareAds = getSquareAds(); // Get the square ads dynamically from the directory

  // Shuffle the ads array to ensure random order
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [adQueue] = useState(shuffleArray([...ads])); // Create a shuffled queue of rectangular ads

  // Select a random square ad from the available square ads
  const [randomSquareAd] = useState(squareAds[Math.floor(Math.random() * squareAds.length)]);

  const applyFilters = (filterInputs) => {
    const { title, author, category } = filterInputs;

    const filtered = articlesData.filter(article =>
      (title === '' || article.title.toLowerCase().includes(title.toLowerCase())) &&
      (author === '' || article.author === author) &&
      (category === '' || article.category === category)
    );
    setFilteredArticles(filtered);
  };

  const insertAds = (articles) => {
    let articlesWithAds = [];
    articles.forEach((article, index) => {
      articlesWithAds.push(
        <ArticleCard
          key={`article-${index}`}
          ref={(el) => articleRefs.current[index] = el} // Attach ref to each article
          title={article.title}
          content={article.content}
          author={article.author}
          publishDate={article.publishDate}
          category={article.category}
          downloadLink={article.downloadLink}
        />
      );

      // Increase the chance of inserting a rectangular ad
      if (Math.random() > 0.2 && index !== articles.length - 1) { // 80% chance of inserting an ad, but not after the last article
        const ad = adQueue[index % adQueue.length]; // Use ads in sequence, but keep it stable
        const adName = ad.split('/').pop().split('.')[0]; // Extract the ad name (filename without extension)
        const adUrl = adLinks[adName]; // Get the corresponding URL from the mapping

        articlesWithAds.push(
          <div key={`rect-ad-${index}`} className="rect-ad-space">
            {adUrl ? (
              <a href={adUrl} target="_blank" rel="noopener noreferrer">
                <img src={ad} alt="Advertisement" className='rectangular-ad'/>
              </a>
            ) : (
              <img src={ad} alt="Advertisement" className='rectangular-ad'/>
            )}
          </div>
        );
      }
    });
    return articlesWithAds;
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const articleIndex = parseInt(hash.replace('#article-', ''), 10);
      if (!isNaN(articleIndex) && articleRefs.current[articleIndex]) {
        articleRefs.current[articleIndex].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id="root">
      <Helmet>
        <title>All Articles | 502Culture</title>
        <meta name="description" content="Browse all cannabis-related articles and news in Spokane by 502Culture. Discover stories about local cultivators, suppliers, and community events." />
      </Helmet>
      <Header />
      <main>
        <div className="content-container">
          <div className="filter-section">
            <div className="filter-box">
              <ArticleFilter
                onFilterApply={applyFilters}
                authors={authors}
                categories={categories}
              />
            </div>
            {/* Dynamic Square Ad Space */}
            <div className="ad-space">
              {randomSquareAd && (
                <a href={adLinks[randomSquareAd.split('/').pop().split('.')[0]]} target="_blank" rel="noopener noreferrer">
                  <img src={randomSquareAd} alt="Logo" className='all-articles-ad'/>
                </a>
              )}
            </div>
          </div>
          <div className="article-list">
            {filteredArticles.length > 0 ? (
              insertAds(filteredArticles)
            ) : (
              <div className="no-articles-message">No results found</div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AllArticles;
