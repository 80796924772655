import React from 'react';
import '../css/SubscribePopup.css'; // Import your CSS for styling the popup

const SubscribePopup = ({ closePopup }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-popup" onClick={closePopup}>X</button>
        <div id="mc_embed_signup">
          <form action="https://lightupenterprises.us13.list-manage.com/subscribe/post?u=f07e92e0dc6d56f75c844fe80&amp;id=d3bb7de643&amp;f_id=00c200e8f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
            <h2>Subscribe to our Mailing List</h2>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">Email Address<span className="asterisk">*</span></label>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">First Name</label>
              <input type="text" name="FNAME" className="text" id="mce-FNAME" />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-LNAME">Last Name</label>
              <input type="text" name="LNAME" className="text" id="mce-LNAME" />
            </div>
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_f07e92e0dc6d56f75c844fe80_d3bb7de643" tabIndex="-1" value="" />
            </div>
            <div className="clear foot">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscribePopup;
