// src/components/Header.js
import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import SubscribePopup from './SubscribePopup';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const togglePopup = (event) => {
    event.preventDefault();
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
        <div className="logo-container" onClick={scrollToTop}>
          <div className="circle">
            <img src={logo} alt="Logo" className='headerimg'/>
          </div>
        </div>
        <div className="nav-container">
          <nav className={`nav-links ${isMenuOpen ? 'open' : 'close'}`}>
            <a href="/">Home</a>
            <a href="/all-articles">All Articles</a>
            <a href="/contact">Contact</a>
            <a href="#subscribe" onClick={togglePopup} className="subscribe-link">Subscribe</a>
          </nav>
          <div 
            className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} 
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`color-lines ${isMenuOpen ? 'expand-lines' : ''}`}>
          <div className="line-yellow"></div>
          <div className="line-orange"></div>
          <div className="line-red"></div>
        </div>
      </header>
      <div
        className={`logo-container ${isScrolled ? 'small-logo' : ''}`}
        onClick={scrollToTop}
      >
        <div className="circle">
          <img src={logo} alt="Logo" className='headerimg'/>
        </div>
      </div>
      {isPopupOpen && <SubscribePopup closePopup={togglePopup} />}
    </>
  );
};

export default Header;
