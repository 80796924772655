import React, { forwardRef } from 'react';

const ArticleCard = forwardRef(({ title, content, author, publishDate, category, downloadLink }, ref) => {
  // Helper function to replace `\n` with line breaks
  const formatContent = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="article-card" ref={ref}>
      <h2>{title}</h2>
      <p><strong>Author:</strong> {author}</p>
      <p><strong>Published on:</strong> {publishDate}</p>
      <p><strong>Category:</strong> {category}</p>
      <p className="article-text">{formatContent(content)}</p>
      {downloadLink && <a href={downloadLink} style={{ margin: 0 }} download>Download Article</a>}
    </div>
  );
});

export default ArticleCard;
