import React, { useState } from 'react';
import logo from '../assets/logo.png'; // Ensure this path is correct
import SubscribePopup from './SubscribePopup';

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-color-lines">
          <div className="line-yellow"></div>
          <div className="line-orange"></div>
          <div className="line-red"></div>
        </div>
        <div className="footer-content">
          <div className="footer-quick-links">
          <h3 className='footer-header' >Quick Links</h3>
            <ul>
              <li><a href="/" className="footer-link">Home</a></li>
              <li><a href="/all-articles">All Articles</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="#subscribe" onClick={togglePopup} className="subscribe-link">Subscribe</a></li>
            </ul>
          </div>
          <div className="footer-logo-container">
            <div className="footer-logo-background">
              <img src={logo} alt="502CultureStatic Logo" className="footer-logo" />
            </div>
          </div>
        </div>
        <div className="footer-built-by">
        <p>
          Built by Trevor and Rvtus, see more{' '}
          <a href="https://rvtus.com/" className="footer-link" target="_blank" rel="noopener noreferrer">
            here
          </a>.
        </p>
        <p>&copy; 2024 502CultureStatic. All rights reserved.</p>
      </div>
      </footer>
      {isPopupOpen && <SubscribePopup closePopup={togglePopup} />} {/* Conditionally render the popup */}
    </>
  );
};

export default Footer;
