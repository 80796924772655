// src/components/AgeVerificationModal.js
import React, { useState } from 'react';
import '../css/ageVerificationModal.css';
import logo from '../assets/logo.png';

const AgeVerificationModal = ({ onConfirm }) => {
  const [underage, setUnderage] = useState(false);

  const handleUnderage = () => {
    setUnderage(true); // Set state to true to show the underage message
  };

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-modal">
        {underage ? (
          <p>You must be at least 21 to enter this site.</p>
        ) : (
          <>
            <div className="veri-logo-container">
                <div className="veri-circle">
                    <img src={logo} alt="Logo" className='logo-header-img'/>
                </div>
            </div>
            <h2>Are you 21?</h2>
            <p>You must be 21 years old or older to enter this site.</p>
            <button className='veri-button' onClick={onConfirm}>I am 21 or older</button>
            <button className='veri-button' onClick={handleUnderage}>I am under 21</button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgeVerificationModal;
